// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

// wml-components
import { generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';

// misc
import { ENV } from '@env/environment';
import {PlatformsVideoDataEditorZeroSectionEnum} from "@shared/services/platforms/platforms.service"

import { WMLFormZeroProps } from '@windmillcode/angular-wml-form';
import { FormsService } from '@shared/services/forms/forms.service';
import { SpecificService } from '@core/specific/specific.service';
import { RichTextEditorZeroProps } from '../rich-text-editor-zero/rich-text-editor-zero.component';
import { WMLChipsZeroProps } from '@windmillcode/angular-wml-chips';
import { MyWMLFileUploadZeroProps } from '@shared/wml-components/overrides';

@Component({
    selector: 'content-editor-control-zero',
    templateUrl: './content-editor-control-zero.component.html',
    styleUrls: ['./content-editor-control-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContentEditorControlZeroComponent {

  constructor(
    public formsService: FormsService,
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService,
    public specificService:SpecificService
  ) { }

  classPrefix = generateClassPrefix('ContentEditorControlZero')
  idPrefix = generateIdPrefix(ENV.idPrefix.contentEditorControlZero)
  @Input('props') props: ContentEditorControlZeroProps = new ContentEditorControlZeroProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId: string = this.idPrefix()
  VideoDataEditorZeroTypeEnum = PlatformsVideoDataEditorZeroSectionEnum
  ngUnsub = new Subject<void>()

  listenForUpdate = () => {
    return this.props.updateSubj
      .pipe(
        takeUntil(this.ngUnsub),
        tap((res?) => {
          this.props = new ContentEditorControlZeroProps({
            ...this.props,
            ...(res ?? this.props)
          })
          // WATCH :is impure if issues make pure by adding type to ContentEditorControlZeroProps and passing it in
          this.mainForm.updateFields([this.fields[this.mainFormGroup.value.type]])
          this.cdref.detectChanges()
        })
      )
  }

  mainForm = new WMLFormZeroProps({})
  mainFormGroup = this.formsService.contentEditorControlZero.mainForm

  createMainFormField = (props:Partial<{createWMLFieldFn:any,errorMsgKeyArray:any,fieldCustomProps:any,formControlName: keyof  FormsService["contentEditorControlZero"]["mainForm"]["value"]}>) => {
    let { formControlName, createWMLFieldFn, errorMsgKeyArray, fieldCustomProps } = props
    let field = this.baseService.createWMLField({
      i18nPrefix: "ContentEditorControlZero.mainForm",
      idPrefixFn: this.idPrefix,
      fieldParentForm: this.mainFormGroup,
      formControlName, createWMLFieldFn, errorMsgKeyArray, fieldCustomProps
    })
    return field
  }


  createTitleField = () => {
    let field = this.createMainFormField({
      formControlName: "title",
      errorMsgKeyArray: ["maxLength"],
      createWMLFieldFn:this.specificService.createRichTextEditorZeroField,
      fieldCustomProps: this.props.title
    })
    field.deleteLabel();
    return field
  }


  createTagsField = () => {
    let field = this.createMainFormField({
      formControlName: "tags",
      errorMsgKeyArray: ["maxItemLength"],
      createWMLFieldFn:this.specificService.createWMLChipsZeroField,
      fieldCustomProps: this.props.tags,
    })
    field.deleteLabel();
    return field
  }

  createDescriptionField = () => {
    let field = this.createMainFormField({
      formControlName: "description",
      errorMsgKeyArray: ["maxLength"],
      createWMLFieldFn:this.specificService.createRichTextEditorZeroField,
      fieldCustomProps: this.props.desc,

    })
    field.deleteLabel();
    return field
  }

  createThumbnailField = () => {
    let field = this.createMainFormField({
      formControlName: "thumbnail",
      errorMsgKeyArray: ["extension","fileSize"],
      createWMLFieldFn:this.specificService.createWMLFileUploadField,
      fieldCustomProps: this.props.thumbmail,

    })
    field.deleteLabel();
    return field
  }

  fields = [
    this.createTitleField(),
    this.createTagsField(),
    this.createDescriptionField(),
    this.createThumbnailField(),
  ]
  initMainForm = () => {
    this.mainFormGroup.value.type
    this.mainForm.updateFields([this.fields[this.mainFormGroup.value.type]])
    this.mainFormGroup.patchValue({
      type:this.mainFormGroup.value.type,
    })

  }

  ngOnInit(): void {
    this.initMainForm()
    this.listenForUpdate().subscribe()
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }
}

export class ContentEditorControlZeroProps {
  constructor(props: Partial<ContentEditorControlZeroProps> = {}) {
    let origProps = Object.entries(props)
      .filter(([key, val]) => {
        return !key.startsWith('prop');
      });
    Object.assign(this, { ...Object.fromEntries(origProps) })
  }

  // type: VideoDataEditorZeroTypeEnum
  title= new RichTextEditorZeroProps()
  tags = new WMLChipsZeroProps({
    placeholder:"global.wmlChipsZero.placeholder"
  })
  desc = new RichTextEditorZeroProps()
  thumbmail = new MyWMLFileUploadZeroProps({

    files:[]
  })
  updateSubj = new Subject<Partial<ContentEditorControlZeroProps>>()
}
