// angular
import { ChangeDetectorRef, Component, HostBinding, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

// wml-components
import { WMLConstructorDecorator, generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';

// misc
import { ENV } from '@env/environment';
import { JobsZeroCommonProps } from '../jobs-zero/jobs-zero';
import { ListJobsEntity } from '@shared/services/jobs/listJobs';
import { RECIPIENT_DELAY_TIME_TO_REQUEST_CHUNK_FOR_FILE_TRANSFER_IN_MS } from '@core/utility/constants';
import { DateTimeZero } from '@core/utility/date-utils';

@Component({
    selector: 'transfer-local-files-job-zero',
    templateUrl: './transfer-local-files-job-zero.component.html',
    styleUrls: ['./transfer-local-files-job-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TransferLocalFilesJobZeroComponent implements OnInit {
  constructor(
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService
  ) {}

  classPrefix = generateClassPrefix('TransferLocalFilesJobZero');
  idPrefix = generateIdPrefix(ENV.idPrefix.transferLocalFilesJobZero);
  @Input('props') props: TransferLocalFilesJobZeroProps = new TransferLocalFilesJobZeroProps();
  @HostBinding('class') myClass: string = this.classPrefix('View');
  @HostBinding('id') myId: string = this.idPrefix();
  ngUnsub = new Subject<void>();
  timeValues = ["days","hours","minutes","seconds"]

  listenForUpdate = () => {
    return this.props.setStateSubj.pipe(
      takeUntil(this.ngUnsub),
      tap((res?) => {
        this.props = new TransferLocalFilesJobZeroProps({
          ...this.props,
          ...(res ?? this.props),
        });

        this.props.files = this.props.files.map((fileInfo)=>{
          let {chunkSize,recipientDelayTime} = (fileInfo.content?.[0]??{})
          if([fileInfo.size,chunkSize,recipientDelayTime].every((val)=>![null,undefined].includes(val))){
            fileInfo.progressPrecision = getProgressMetrics(fileInfo.size,chunkSize,recipientDelayTime).precision

          }
          return fileInfo
        })
        this.cdref.detectChanges();
      })
    );
  };

  ngOnInit(): void {
    this.listenForUpdate().subscribe();
  }

  ngOnDestroy(): void {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}

@WMLConstructorDecorator
export class TransferLocalFilesJobZeroProps extends JobsZeroCommonProps {
  constructor(props: Partial<TransferLocalFilesJobZeroProps> = {}) {
    super();
  }

  files:ListJobsEntity["details"]["transferLocalFilesDetails"]["files"]
  clientType:ListJobsEntity["details"]["transferLocalFilesDetails"]["clientType"]
  recipients:ListJobsEntity["details"]["transferLocalFilesDetails"]["recipients"]
  senders:ListJobsEntity["details"]["transferLocalFilesDetails"]["senders"]
  setStateSubj = new Subject<TransferLocalFilesJobZeroProps>();
  estimatedTimeInMs  =0
  get estimatedTime (){
    let myVal = new DateTimeZero()
    myVal.normalize(this.estimatedTimeInMs,"milliseconds")
    return myVal
  }
  setState = (value) => {
    this.setStateSubj.next(value);
  };
}


// TODO until we can figure out how to calucalate the accurate time with .001
export function getProgressMetrics(size,chunkSize=262144,recipientDelayTimeInMs=RECIPIENT_DELAY_TIME_TO_REQUEST_CHUNK_FOR_FILE_TRANSFER_IN_MS) {

  recipientDelayTimeInMs = Math.max(recipientDelayTimeInMs,.05)
  // Calculate the number of updates per second
  let updatesPerSecond = Math.round(1000 / recipientDelayTimeInMs);

  // Calculate the total number of updates for the entire file transfer
  let totalUpdates = Math.round(size / updatesPerSecond);

  // Calculate the percentage increase per update
  let percentageIncreasePerUpdate = 100 / totalUpdates;

  // Determine the precision needed to avoid lagging percentage increases
let precision = 0;
  if (percentageIncreasePerUpdate < 1) {
    precision = Math.ceil(-Math.log10(percentageIncreasePerUpdate)) + 2;
    precision -= 5;
  }
  precision = Math.max(precision,2)
  let estimatedTimeInMs = (size / chunkSize) * (recipientDelayTimeInMs+140);


  return { precision, estimatedTimeInMs };
}
