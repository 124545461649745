import { SITE_OFFLINE_ENUM } from '@core/utility/constants';
import { EnvPlatformType, traverseClassAndRemoveUpdateAutomation } from '@core/utility/env-utils';
import { makeTitleCase } from '@core/utility/string-utils';

import { WMLEndpoint,WMLUri } from '@windmillcode/wml-components-base';
import { PeerOptions } from 'peerjs';

export let environment = {
  production: false,
};


export class DevEnv {
  // this must be a union type for import purposes
  type: 'DEV' | 'DOCKER_DEV' | 'PREVIEW' | 'PROD' | 'TEST' = 'DEV';
  platformType = EnvPlatformType.WEB
  endpointMsgCodes = {
    success: 'OK',
    error: 'ERROR',
    respViaSocketio: 'RESPONSE_VIA_SOCKETIO',
  };

  app = (() => {
    let app = {

      shouldPerformInitialNavigate:()=>{
        return navigator.userAgent ==="Puppeteer" || !["PREVIEW","PROD"].includes(this.type)
      },
      backendHealthCheck: () => this.backendDomain0 + '/healthz/',
      siteOffline: SITE_OFFLINE_ENUM.FALSE,
      originalConsoleObj: [],
      keyBoardDebounceTimes:[500],
      // dev additions
      freeTrialEndsAfter: Infinity,
      toPassChromeOauthVerficationTest:true,
      includeSubscriptions:false,
      friendsLimit:5,
      isRemote:false //remember when setting to true you have to run the app in http because the emulator rejects your certificate as its not in the cert store of the remote device // TODO just know when deploying always set isRemote to false

    };
    return app;
  })();

  backendServerName0 = 'example.com:5003';

  get backendDomain0() {
    return 'https://' + this.backendServerName0;
  }
  frontendDomain0 = 'https://example.com:4203';
  // TODO Function or literal
  backendURI0 = new WMLUri({
    host:"example.com",
    port:5003
  })

  classPrefix = {
    app: 'App',
    products: 'Products',
  };

  idPrefix: { [k: string]: string } = {

    accountLayout: 'AccountLayout_',
    authLayout: 'AuthLayout_',
    landingLayout: 'LandingLayout_',
    defaultLayout: 'DefaultLayout_',

    accountOverviewPage: 'AccountOverviewPage_',
    billingZeroPage: 'BillingZeroPage_',
    connectionHubPage: 'ConnectionHubPage_',
    forgotPassPage: 'ForgotPassPage_',
    friendsZeroPage: 'FriendsZeroPage_',
    guideZeroPage: 'GuideZeroPage_',
    legalDocPage: 'LegalDocPage_',
    signUpPage: 'SignUpPage_',
    signInPage: 'SignInPage_',
    surveyPage: 'SurveyPage_',
    UploadZeroPage: 'UploadZeroPage_',
    verifyEmailZeroPage: 'VerifyEmailZeroPage_',
    videoDataEditorPage: 'VideoDataEditorPage_',
    videoManagerPage: 'VideoManagerPage_',

    addNewVideoOne: 'AddNewVideoOne_',
    addNewVideoZero: 'AddNewVideoZero_',
    aiShortsJobZero: 'AiShortsJobZero_',
    backupPreviousStateJobZero: 'BackupPreviousStateJobZero_',
    cardOne: 'CardOne_',
    cardTwo: 'CardTwo_',
    cardZero: 'CardZero_',
    confirmDialogZero: 'ConfirmDialogZero_',
    contentEditorControlZero: 'ContentEditorControlZero_',
    currentPageControlZero: 'CurrentPageControlZero_',
    dashboardCtrlZero: 'DashboardCtrlZero_',
    datePickerZero: 'DatePickerZero_',
    downloadJobZero: 'DownloadJobZero_',
    dropdownOptionTwo: 'DropdownOptionTwo_',
    dropdownTwo: 'DropdownTwo_',
    filterZero: 'FilterZero_',
    friendControlZero: 'FriendControlZero_',
    imageUploadZero: 'ImageUploadZero_',
    insertAtControlZero: 'InsertAtControlZero_',
    intervalInputZero: 'IntervalInputZero_',
    jobStatusZero: 'JobStatusZero_',
    jobTitleZero: 'JobTitleZero_',
    jobsZero: 'JobsZero_',
    limitsZero: 'LimitsZero_',
    logoImgZero: 'LogoImgZero_',
    multiInputOne: 'MultiInputOne_',
    multiInputZero: 'MultiInputZero_',
    navOne: 'NavOne_',
    navZero: 'NavZero_',
    optionZero: 'OptionZero_',
    pageNumberControlZero: 'PageNumberControlZero_',
    pricingCardZero: 'PricingCardZero_',
    pricingZero: 'PricingZero_',
    richTextEditorZero: 'RichTextEditorZero_',
    searchZero: 'SearchZero_',
    sortZero: 'SortZero_',
    transferLocalFilesJobZero: 'TransferLocalFilesJobZero_',
    transferRecipientsFormZero: 'TransferRecipientsFormZero_',
    updateVideosJobZero: 'UpdateVideosJobZero_',
    verifiedZero: 'VerifiedZero_',
    videoDataEditorZero: 'VideoDataEditorZero_',
    videoTileZero: 'VideoTileZero_',
    videoViewerZero: 'VideoViewerZero_',



    nav: 'Nav_',




  };

  nav = (() => {
    let nav = {
      urls: {

        landing: '/landing',
        home: '/',
        homeAlt: '/home',
        siteOffline: '/site-offline',
        notFound:"/404",
        initialURL: '/',
        authLayout: '/auth',
        signUpPage: '/auth/sign-up',
        signInPage: '/auth/sign-in',
        forgotPassPage: '/auth/forgot-pass',
        verifyEmailZeroPage:"/auth/verify-email",
        accountLayout: '/account',
        accountOverviewPage: '/account/overview',
        friendsZeroPage:"/account/friends",
        billingZeroPage:"/account/billing",
        connectionHub: '/hub',
        legalDocPage:"/legal",
        termsAndConditionsPage:"/legal/terms-and-conditions",
        privacyPolicyPage:"/legal/privacy-policy",
        surveyPage:"/survey",
        manageVideos: '/videos/manage',
        videoDataEditorPage:"/videos/data-editor",
        uploadPage:"/upload",
        guide:"/guide",

      },
    };

    let idPrefixes = Object.entries(nav.urls).map(([key, val]) => {
      return [key, makeTitleCase(key) + '_'];
    });
    this.idPrefix = {
      ...Object.fromEntries(idPrefixes),
      ...this.idPrefix,
    };
    return nav;
  })();

  mobile = {
    android:{
      appUrl:"",
      googlePlayStore:{
        storeUrl:'https://play.google.com/store/apps/details?id=com.windmillcode.eneobia&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',

      }
    },
    ios:{
      appUrl:"",
      appStore:{
        storeUrl:"https://apps.apple.com/us/app/eneobia/id6505082387"
      }
    }
  }


  errorCodes = {};

  peerjs:PeerOptions = {
    host:"example.com",
    port:5003,
    secure:true,
    // port:9503,
    // secure:false,
    clientType:"socketio",
    msgpackType:"standard",
    // debug:4
  }

  tawkTo = {
    scriptUrl:'https://embed.tawk.to/66187af1a0c6737bd12ae615/1hr7q0ggs'
  }

  oneSignal = {
    appId:"fef48508-5e84-4353-a456-718d406f1f50",
    safariWebId:"web.onesignal.auto.10485988-1822-4e96-b399-29edb7cde282"
  }

  hcaptcha = {
    siteKey:"7dee141b-7877-4153-bef2-b4e04ef037e1"
  }

  firebase = (() => {
    let firebase

    firebase = {
      config: {
        apiKey: 'AIzaSyAOntpUM6jJ5hWM9K6T1W_1vtKdTbfxEpw',
        authDomain: '127.0.0.1',
        // authDomain: "ui.preview.eneobia.com",
        projectId: 'eneobia-preview',
        messagingSenderId: '940530829997',
        appId: '1:940530829997:web:44c1e7991a54d6c66fd424',
        measurementId: 'G-0TJZF5K9BF',
      },
      get storageImageURL(){
        return firebase._storageImageURL
      },
      set storageImageURL(value){
        firebase._storageImageURL = value
      },
      getImageUrlFromStorage: (resourcePath?: string) => {
        return `${firebase.storageImageURL}${encodeURIComponent(
          resourcePath,
        )}?alt=media`;
      },
    };
    firebase.storageImageURL =`http://${firebase.config.authDomain}:9199/v0/b/eneobia-preview.appspot.com/o/`
    return firebase;
  })();

  localForage = {
    UserVideoData:"UserVideoData",
    SitePassword:"SitePassword",
    VideoDataEditor:"VideoDataEditor",
    LoginFromFirebaseCustomToken:"LoginFromFirebaseCustomToken"
  }

  accountsService = {
    listUsers: new WMLEndpoint({
      url: () => this.backendDomain0 + '/accounts/users/list',
    }),
    updateUser: new WMLEndpoint({
      url: () => this.backendDomain0 + '/accounts/users/update',
    }),
    mapSocketIOSessionIdToUser:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/accounts/socketio/map",
    }),
    updateAdresses: new WMLEndpoint({
      url: () => this.backendDomain0 + '/accounts/addresses/update',
    }),
    deleteUser: new WMLEndpoint({
      url: () => this.backendDomain0 + '/accounts/users/delete',
    }),
    deleteUserData: new WMLEndpoint({
      url: () => this.backendDomain0 + '/accounts/users/delete-data',
    }),
    exportUsers: new WMLEndpoint({
      url: () => this.backendDomain0 + '/accounts/users/export',
    }),
    addCardToUser: new WMLEndpoint({
      url: () => this.backendDomain0 + '/accounts/cards/create',
    }),
    listCards: new WMLEndpoint({
      url: () => this.backendDomain0 + '/accounts/cards/list',
    }),
    deleteCards: new WMLEndpoint({
      url: () => this.backendDomain0 + '/accounts/cards/delete',
    }),
    getCardServerSessionId: new WMLEndpoint({
      url: () => 'https://elb.deposit.shopifycs.com/sessions',
    }),

    registerViaPassword: new WMLEndpoint({
    }),
    loginViaPassword: new WMLEndpoint({
    }),
    loginViaCustomToken: new WMLEndpoint({
    }),
    EmailPasswordRecoveryLink: new WMLEndpoint({
    }),
    changePassword: new WMLEndpoint({
    }),
    listFriends:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/accounts/friends/list",
    }),
    updateFriends:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/accounts/friends/update",
    }),
    listCredits:new WMLEndpoint({
        url:()=> this.backendDomain0 + "/accounts/credits/list",
      }),
    getCustomTokens:new WMLEndpoint({
        url:()=> this.backendDomain0 + "/accounts/custom-tokens/list",
      }),
  };

  storeService = {
    listSubscriptions: new WMLEndpoint({
      url: () => this.backendDomain0 + '/store/subscriptions/list',
    }),
    purchaseSubscription: new WMLEndpoint({
      url: () => this.backendDomain0 + '/store/subscriptions/purchase',
    }),
    cancelSubscription:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/store/subscriptions/cancel",
    }),
    purchaseCredits:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/store/credits/purchase",
    }),
    listCredits:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/store/credits/list",
    }),
    useCredits:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/store/credits/use",
    }),
  };

  platformsService = {
    requestYoutubeAuthorizationFromUser: new WMLEndpoint({
      url: () => this.backendDomain0 + '/platforms/youtube/authorize/request',
    }),
    revokeYoutubeAuthorizationForUser: new WMLEndpoint({
      url: () => this.backendDomain0 + '/platforms/youtube/authorize/revoke',
    }),
    listVideos: new WMLEndpoint({
      url: () => this.backendDomain0 + '/platforms/videos/list',
    }),
    addVideos:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/platforms/videos/create",
    }),
    deleteVideos:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/platforms/videos/delete",
    }),
    listUsersCurrentVideoData:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/platforms/current-data/list",
    }),
    listUsersVideoData:new WMLEndpoint({
      automate:false,
      url:()=> this.backendDomain0 + "/platforms/data/list",
    }),
    updateVideos:new WMLEndpoint({
        url:()=> this.backendDomain0 + "/platforms/videos/update",
      }),
    saveUserVideoDataEditorSettings:new WMLEndpoint({
        url:()=> this.backendDomain0 + "/platforms/data-editor/save",
      }),
    loadUserVideoDataEditorSettings:new WMLEndpoint({
        url:()=> this.backendDomain0 + "/platforms/data-editor/load",
      }),
    backupPreviousState:new WMLEndpoint({
        url:()=> this.backendDomain0 + "/platforms/data-editor/backup",
      }),


  };

  jobsService = {
    listJobs:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/platforms/jobs/list",
    }),
    runJobs:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/platforms/jobs/run",
    }),
    updateJobs:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/platforms/jobs/update",
    }),
    requestFileTransfer:new WMLEndpoint({
        url:()=> this.backendDomain0 + "/jobs/file-transfer/request",
      }),
    respondToFileTransferRequest:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/jobs/file-transfer/respond",
    }),
  }

  storageService = {
    requestGoogleDriveAuthorizationFromUser:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/storage/google-drive/authorize/request",
    }),
    revokeGoogleDriveAuthorizationFromUser:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/storage/google-drive/authorize/revoke",
    }),
  }
  constructor() {
    this.app.originalConsoleObj = Object.entries(console);
    traverseClassAndRemoveUpdateAutomation(this)
    if(this.app.isRemote){
      this.backendServerName0 ='0xdaf8738d7d6dca8774f2f2742cbd7be913e9c3eb.diode.link'
      this.firebase.config.authDomain ='192.168.1.90'
    }
  }
}

export let ENV = new DevEnv();
