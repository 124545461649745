// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, SecurityContext, ViewChild, ViewContainerRef } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

// wml-components
import { generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';

// misc
import { ENV } from '@env/environment';
import Quill from 'quill';
import { WMLFieldZeroProps } from '@windmillcode/angular-wml-field';
import { DomSanitizer } from '@angular/platform-browser';
import { convertHtmlToPreFormattedText } from '@core/utility/rich-text-utils';


@Component({
    selector: 'rich-text-editor-zero',
    templateUrl: './rich-text-editor-zero.component.html',
    styleUrls: ['./rich-text-editor-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RichTextEditorZeroComponent {
  constructor(
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService,
    public sanitizer: DomSanitizer
  ) { }

  classPrefix = generateClassPrefix('RichTextEditorZero')
  idPrefix = generateIdPrefix(ENV.idPrefix.richTextEditorZero)
  @Input('props') props: RichTextEditorZeroProps = new RichTextEditorZeroProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId: string = this.idPrefix()
   @ViewChild("quillEditor",{read:ViewContainerRef}) quillEditor :ViewContainerRef
  ngUnsub = new Subject<void>()
  quill:Quill

  listenForUpdate = () => {
    return this.props.updateSubj
      .pipe(
        takeUntil(this.ngUnsub),
        tap((res?) => {
          this.props = new RichTextEditorZeroProps({
            ...this.props,
            ...(res ?? this.props)
          })
          this.cdref.detectChanges()
        })
      )
  }

  sanitizeHTML = (unsafeHTML)=>{
    return this.sanitizer.sanitize(SecurityContext.HTML,unsafeHTML)
  }


  listenForTextChange = (delta, oldDelta, source)=>{
    let text = this.quill.root.innerText
    text = text.replace(/\n\n/g, '\n');
    let control = this.props.wmlField.getReactiveFormControl()
    if((text??"").trim() === ''){
      control.patchValue("")
    }
    else{
      control.patchValue(convertHtmlToPreFormattedText(this.sanitizeHTML(text)))
      // control.patchValue(text)
    }
    control.markAsDirty()
  }




  ngOnInit(): void {
    this.listenForUpdate().subscribe()
  }

  ngAfterViewInit() {
    this.quill = new Quill(this.quillEditor.element.nativeElement, {
      theme: 'snow',
      modules:{
        toolbar:true,
        // "emoji-toolbar": true,
        "emoji-textarea": true,
        // "emoji-shortname":true,
      },
    });
    this.quill.on('text-change',this.listenForTextChange)
    let control = this.props.wmlField.getReactiveFormControl()
    this.quill.clipboard.dangerouslyPasteHTML(this.sanitizeHTML(`<pre>${control.value}</pre>`))
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }
}

export class RichTextEditorZeroProps {
  constructor(props: Partial<RichTextEditorZeroProps> = {}) {
    let origProps = Object.entries(props)
      .filter(([key, val]) => !key.startsWith('prop'));
    Object.assign(this, { ...Object.fromEntries(origProps) })
  }

  updateSubj = new Subject<RichTextEditorZeroProps>()
  wmlField:WMLFieldZeroProps
}
