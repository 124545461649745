import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';
import { Subject, takeUntil, tap } from 'rxjs';
import { generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';
import { ENV } from '@env/environment';
import { formatBytes } from '@core/utility/file-utils';
import { JobsZeroCommonProps } from '../jobs-zero/jobs-zero';

@Component({
    selector: 'download-job-zero',
    templateUrl: './download-job-zero.component.html',
    styleUrls: ['./download-job-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DownloadJobZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService
  ) { }

  classPrefix = generateClassPrefix('DownloadJobZero')
  idPrefix = generateIdPrefix(ENV.idPrefix.downloadJobZero)
  @Input('props') props: DownloadJobZeroProps = new DownloadJobZeroProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()

  get displayTotalFileSize(){
    // @ts-ignore
    let totalFileSize =this.props.items.reduce((acc,curr)=>{
      return acc + curr.fileSize
    },0)
    return formatBytes(totalFileSize)
  }


  deleteVideo = (item:DownloadJobZeroItemProps)=>{
    this.props.items = this.props.items.filter((i)=>i !== item)
    this.props.deleteVideoEvent.next({
      type:"deleteVideo",
      val:this.props.items
    })
    this.cdref.detectChanges()
  }

  listenForUpdate = ()=>{
    return this.props.setStateSubj
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res?)=>{
        this.props = new DownloadJobZeroProps({
          ...this.props,
          ...(res ?? this.props)
        })
        this.cdref.detectChanges()
      })
    )
  }

  ngOnInit(): void {
    this.listenForUpdate().subscribe()
  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }
}

export  class DownloadJobZeroItemProps {
  constructor(props: Partial<DownloadJobZeroItemProps> = {}) {
    let origProps = Object.entries(props)
      .filter(([key,val]) => {
        return !key.startsWith('prop');
      });
    Object.assign(this, { ...Object.fromEntries(origProps) });

  }
  title: string = '';
  downloadUrl: string = '';
  fileSize:number = 0;
  get displayFileSize(){
    let result = formatBytes(this.fileSize);
    return result
  }
  thumbnailUrl: string = '';
  click:Function
}


export class DownloadJobZeroProps extends JobsZeroCommonProps {
  constructor(props:Partial<DownloadJobZeroProps>={}){
    super(props)
    let origProps = Object.entries(props)
    .filter(([key,val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) })
  }

  items:DownloadJobZeroItemProps[]=[]
  setStateSubj = new Subject<Partial<DownloadJobZeroItemProps>|void>()
  setState=(val:Partial<DownloadJobZeroItemProps>|void)=>{
    this.setStateSubj.next(val)
  }
  deleteVideoEvent = new Subject<{
    type:"deleteVideo",
    val:DownloadJobZeroItemProps[]
  }>()
}


