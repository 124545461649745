// angular
import {ChangeDetectionStrategy,ChangeDetectorRef,Component,HostBinding,Input,} from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject, map, merge, startWith, takeUntil, tap, withLatestFrom, distinctUntilChanged } from 'rxjs';

// wml-components
import {
  WMLCustomComponent,
  generateClassPrefix,
  generateIdPrefix,
  replaceValuesWithPaths,
} from '@windmillcode/wml-components-base';

// misc
import { ENV } from '@env/environment';
import { NavService } from '@shared/services/nav/nav.service';
import { WMLButtonPropsTypeEnum, WMLButtonOneProps } from '@windmillcode/angular-wml-button';
import enTranslations from "src/assets/i18n/en.json";
import { makeLowerCase } from '@core/utility/string-utils';
import { WMLInfiniteDropdownZeroOption, WMLInfiniteDropdownZeroProps } from '@windmillcode/angular-wml-infinite-dropdown';
import { DropdownOptionTwoComponent, DropdownOptionTwoProps } from '../dropdown-option-two/dropdown-option-two.component';
import { NavigationEnd } from '@angular/router';
import { AccountsService } from '@app/shared/services/accounts/accounts.service';
import { WMLAngularMotionUIProperty } from '@windmillcode/angular-wml-components-base';
@Component({
    selector: 'nav-zero',
    templateUrl: './nav-zero.component.html',
    styleUrls: ['./nav-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NavZeroComponent {
  constructor(
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService,
    public navService: NavService,
    public accountsService:AccountsService
  ) {}

  classPrefix = generateClassPrefix('NavZero');
  idPrefix = generateIdPrefix(ENV.idPrefix.navZero);
  @Input('props') props: NavZeroProps = new NavZeroProps();
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId: string = this.idPrefix();
  viewType: "all" | "legal" |"survey"  ="all"


  ngUnsub = new Subject<void>();
  accountProfileBtn = new WMLButtonOneProps({
    text:"NavZero.myAccount",
    type:WMLButtonPropsTypeEnum.TERTIARY,
    routerLink:ENV.nav.urls.accountOverviewPage

  })
  takeSurveyBtn = new WMLButtonOneProps({
    text:"NavZero.takeSurvey",
    type:WMLButtonPropsTypeEnum.TERTIARY,
    routerLink:ENV.nav.urls.surveyPage
  })

  langsDropdown = new WMLInfiniteDropdownZeroProps({
    isPresent:false,
    items:replaceValuesWithPaths(
      enTranslations.global.langAbbreviations,
      "global.langAbbreviations."
    ).map((text,index0)=>{
      let langCode = enTranslations.global.langAbbreviations[index0]
      return new WMLInfiniteDropdownZeroOption({
        text,
        custom:new WMLCustomComponent({
          cpnt:DropdownOptionTwoComponent,
          props:new DropdownOptionTwoProps({
            text,
            click:()=>{
              this.utilService.changeLanguage(makeLowerCase(langCode))
              this.utilService.currentLang = text
              this.cdref.detectChanges()
            },
          })
        })
      })
    })
  })

  nav = new WMLAngularMotionUIProperty({
    autoOpen:false,
    cdref:this.cdref,
    class: this.classPrefix('Pod1Item1'),

    style:{
      animationDuration: '0.2s',
      animationDelay: '0.2s',
    },
    keyFrameStyles:{
      "0%":{
        opacity:"0",
        visibility: "hidden",
        "pointerEvents": "none"
      },
      "100%":{
        opacity:"1",
        visibility: "visible",
        "pointerEvents": "auto"
      }
    }
  })

  toggleNav = (action,predicate?)=>{

    predicate?.()
    if(action === "close"){
      this.nav.closeMotion()
    }
    else{
      setTimeout(() => {
        // TODO refactor logic into lib
        this.nav.style = Object.assign(
          this.nav.style,{
            visibility: "visible",
            "pointerEvents": "auto"
          }
        )
        this.cdref.detectChanges()
      }, 100);
      this.nav.openMotion()
    }
    this.cdref.detectChanges()
  }



  listenForRouteChange = ()=>{
    return merge(
      this.utilService.router.events.pipe(
        withLatestFrom(this.accountsService.onUserEvent)
      ),
      this.accountsService.onUserEvent.pipe(
        withLatestFrom(
          this.utilService.router.events.pipe(
            startWith(null)
          )
        )
      )
    )
    .pipe(
      map(([eventOrUserEvent, otherValue]) => {

        let result:[any, string] //[Event_2, string]
        if (typeof eventOrUserEvent === 'string') {
          result = [otherValue, eventOrUserEvent]
        } else {
          result = [eventOrUserEvent, otherValue] as any
        }
        return result
      }),
      takeUntil(this.ngUnsub),
      distinctUntilChanged((prev, curr) => {
        let result = [prev[0],curr[0]].every((val)=>
          val  instanceof NavigationEnd) && prev[1] !== curr[1]
        return !result
      }),
      tap((res)=>{
        let legalRoutes = [ENV.nav.urls.termsAndConditionsPage,ENV.nav.urls.privacyPolicyPage,ENV.nav.urls.guide]
        let surveyRoutes = [ENV.nav.urls.surveyPage]
        this.viewType = "all"
        if(["userLoggedIn"].includes(res[1])){

        }
        else if(legalRoutes.includes(this.utilService.router.url) ){
          this.viewType = "legal"
        }
        else if(surveyRoutes.includes(this.utilService.router.url)){
          this.viewType = "survey"
        }

        this.cdref.detectChanges()
      })
    )

  }

  toggleLangs = ()=>{
    this.langsDropdown.isPresent = !this.langsDropdown.isPresent
    this.cdref.detectChanges()
  }

  ngOnInit(): void {
    this.listenForRouteChange().subscribe()
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}

export class NavZeroProps {
  constructor(props: Partial<NavZeroProps> = {}) {
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
}
