import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseServiceSubmitFormProps , BaseService } from '@core/base/base.service';
import { Subject, takeUntil, tap } from 'rxjs';
import {  WMLConstructorDecorator, generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';
import { ENV } from '@env/environment';
import { WMLSelectZeroProps } from '@windmillcode/angular-wml-select';
import { WMLFormZeroProps } from '@windmillcode/angular-wml-form';
import { WMLButtonOneProps, WMLButtonPropsTypeEnum } from '@windmillcode/angular-wml-button';
import { FormsService } from '@shared/services/forms/forms.service';
import { FormGroup, FormControl } from '@angular/forms';
import enTranslations from "src/assets/i18n/en.json";
import { WMLInfiniteDropdownZeroOption } from '@windmillcode/angular-wml-infinite-dropdown';
import { EventSubjBodyZero } from '@core/utility/common-utils';
import { ListJobsEntity } from '@shared/services/jobs/listJobs';



@Component({
    selector: 'job-title-zero',
    templateUrl: './job-title-zero.component.html',
    styleUrls: ['./job-title-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class JobTitleZeroComponent  {
  constructor(
    public formsService:FormsService,
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService
  ) { }
  classPrefix = generateClassPrefix('JobTitleZero')
  idPrefix = generateIdPrefix(ENV.idPrefix.jobTitleZero)
  @Input('props') props: JobTitleZeroProps = new JobTitleZeroProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()
  selectJobBtn = new WMLButtonOneProps({
    type:WMLButtonPropsTypeEnum.SECONDARY,
    text:"JobTitleZero.selectJobBtn",
    click:()=>this.props.jobChosenSubj.next(new EventSubjBodyZero({value:!this.props.jobIsChosen}))
  })

  mainForm = new WMLFormZeroProps({})
  mainFormGroup = new FormGroup({
    title:new FormControl(null)
  })
  createMainFormField = (props:Partial<{createWMLFieldFn:any,errorMsgKeyArray:any,fieldCustomProps:any,formControlName: keyof  JobTitleZeroComponent["mainFormGroup"]["value"]}>)=>{
    let {formControlName,createWMLFieldFn,errorMsgKeyArray,fieldCustomProps} = props
    let field =this.baseService.createWMLField({
      i18nPrefix:"JobTitleZero.mainForm",
      idPrefixFn:this.idPrefix,
      fieldParentForm:this.mainFormGroup,
      formControlName,createWMLFieldFn,errorMsgKeyArray,fieldCustomProps
    })
    return field
  }

  mainFormClickSubmitBtn = ()=>{
    this.baseService.submitForm(new BaseServiceSubmitFormProps({
      rootFormGroup:this.mainFormGroup,
      cdref:this.cdref,
      validFormPredicateTypeCustom:()=>{

      }
    }))
  }
  createTitleField =()=>{
    let values:Array<ListJobsEntity["jobType"]> = ["BULK_DOWNLOAD" , "SHORTS_GENENERATION","BACKUP_PREVIOUS_STATE","UPDATE_VIDEOS",'TRANSFER_LOCAL_FILES']

    let jobTypeIndex = values.findIndex((item)=>this.props.jobType === item)
    let options = enTranslations.JobTitleZero.mainForm.titleField.options
    .map((value,i)=>{
      return new WMLInfiniteDropdownZeroOption({
        text: "JobTitleZero.mainForm.titleField.options."+i,
        value:values[i]
      });
    })
    if(ENV.app.toPassChromeOauthVerficationTest){
      options.shift()
    }
    let field= this.createMainFormField({
      createWMLFieldFn:this.baseService.createSelectField,
      formControlName:"title",
      errorMsgKeyArray:[],
      fieldCustomProps:new WMLSelectZeroProps({
        select:"JobTitleZero.mainForm.titleField.options."+jobTypeIndex,
        // @ts-ignore
        options
      })
    })
    field.deleteLabel();
    return field
  }

  listenForJobTypeChange = ()=>{

    return this.mainFormGroup.controls.title.valueChanges
    .pipe(
      takeUntil(this.ngUnsub),
      // TODO  raw string should not be coming from valueChanges
      tap((value)=>{
        this.props.jobType = value?.value ?? value  ??"BULK_DOWNLOAD"
        this.props.jobTypeChangeEvent.next(this.props.jobType)
      })
    )

  }
  initMainForm = ()=>{

    let fields = [
      this.createTitleField(),
    ]
    this.mainForm.updateFields(fields)

  }

  listenForJobChosen = ()=>{

    return this.props.jobChosenSubj
    .pipe(
      takeUntil(this.ngUnsub),
      tap((value)=>{
        this.selectJobBtn.updateSubj.next({
          text:value.value  ? "JobTitleZero.selectJobBtnChosen" : "JobTitleZero.selectJobBtn"
        })
        this.props.jobIsChosen = value.value
        if(value.emit){
          this.props.jobChosenEvent.next(this.props.jobIsChosen)
        }
        this.cdref.detectChanges()
      })
    )

  }


  ngOnInit(): void {
    this.initMainForm()

  }
  ngAfterViewInit(){
    this.listenForJobTypeChange().subscribe()
    this.listenForJobChosen().subscribe()
    this.props.jobChosenSubj.next(new EventSubjBodyZero({value:this.props.jobIsChosen,emit:false}))
  }
  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }
}

@WMLConstructorDecorator
export class JobTitleZeroProps {
  constructor(props:Partial<JobTitleZeroProps>={}){}
  jobType:  ListJobsEntity["jobType"] = "BULK_DOWNLOAD"
  jobIsChosen: boolean = false
  jobTypeChangeEvent = new Subject<JobTitleZeroProps["jobType"]>()
  jobChosenEvent = new Subject<boolean>()
  jobChosenSubj = new Subject<EventSubjBodyZero>()
}
