import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

// wml-components
import { WMLConstructorDecorator, WMLUIProperty, generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';

// misc
import { ENV } from '@env/environment';
import { WMLFieldZeroProps } from '@windmillcode/angular-wml-field';

@Component({
    selector: 'interval-input-zero',
    templateUrl: './interval-input-zero.component.html',
    styleUrls: ['./interval-input-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IntervalInputZeroComponent {
  constructor(
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService
  ) {}

  classPrefix = generateClassPrefix('IntervalInputZero');
  idPrefix = generateIdPrefix(ENV.idPrefix.intervalInputZero);
  @Input('props') props: IntervalInputZeroProps = new IntervalInputZeroProps();
  @HostBinding('class') myClass: string = this.classPrefix('View');
  @HostBinding('id') myId: string = this.idPrefix();
  ngUnsub = new Subject<void>();


  listenForUpdate = () => {
    return this.props.setStateSubj.pipe(
      takeUntil(this.ngUnsub),
      tap((res?) => {
        this.props = new IntervalInputZeroProps({
          ...this.props,
          ...(res ?? this.props),
        });
        this.cdref.detectChanges();
      })
    );
  };



  increase =()=>{
    let newValue = this.formGroup.value[this.formControlName] + this.props.interval
    if(newValue <= this.props.max){
      this.formGroup.patchValue({
        [this.formControlName]:newValue
      })
      this.cdref.detectChanges()
    }
  }

  decrease =()=>{
    let newValue = this.formGroup.value[this.formControlName] -this.props.interval
    if(newValue >= this.props.min){
      this.formGroup.patchValue({
        [this.formControlName]:newValue
      })
      this.cdref.detectChanges()
    }

  }

  get formGroup(){
    return this.props.wmlField.getParentForm()
  }
  get formControlName(){
    return this.props.wmlField.getFormControlName()
  }
  get formControl(){
    return this.props.wmlField.getReactiveFormControl()
  }

  ngOnInit(): void {

    this.formGroup.patchValue({
      [this.formControlName]:this.props.startingInterval
    })
    this.listenForUpdate().subscribe();
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}

@WMLConstructorDecorator
export class IntervalInputZeroProps {
  constructor(props: Partial<IntervalInputZeroProps> = {}) {}

  pod0 = new WMLUIProperty({

  })
  wmlField = new WMLFieldZeroProps()
  interval = 5
  startingInterval = 5
  min = 0
  max = Infinity
  setStateSubj = new Subject<IntervalInputZeroProps>();
  setState = (value) => {
    this.setStateSubj.next(value);
  };
}
