import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit, Input } from '@angular/core';
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseServiceSubmitFormProps, BaseService } from '@core/base/base.service';
import { Subject,map,concatMap, takeUntil, tap } from 'rxjs';
import { WMLConstructorDecorator, generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';
import { ENV } from '@env/environment';
import { CardOneProps } from '../card-one/card-one.component';
import { WMLFormZeroProps } from '@windmillcode/angular-wml-form';
import { FormsService } from '@shared/services/forms/forms.service';
import { SpecificService } from '@core/specific/specific.service';
import { MultiInputOneFieldCreatorProps, MultiInputOneProps } from '../multi-input-one/multi-input-one.component';
import { WMLFileUploadZeroItem } from '@windmillcode/angular-wml-file-manager';
import { FormControl } from '@angular/forms';
import { SocketioService } from '@shared/services/socketio/socketio.service';
import { JobsService } from '@shared/services/jobs/jobs.service';
import { RequestFileTransferUIRequestBody } from '@shared/services/jobs/requestFileTransfer';
import { ListJobsEntity } from '@shared/services/jobs/listJobs';
import { AccountsService } from '@shared/services/accounts/accounts.service';
import { deepCopyInclude } from '@core/utility/object-utils';

@Component({
    selector: 'transfer-recipients-form-zero',
    templateUrl: './transfer-recipients-form-zero.component.html',
    styleUrls: ['./transfer-recipients-form-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TransferRecipientsFormZeroComponent implements OnInit {
  constructor(
    public formsService: FormsService,
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService,
    public specificService:SpecificService,
    public jobsService:JobsService,
    public socketioService:SocketioService,
    public accountService:AccountsService
  ) {}

  classPrefix = generateClassPrefix('TransferRecipientsFormZero');
  idPrefix = generateIdPrefix(ENV.idPrefix.transferRecipientsFormZero);

  @Input('props') props: TransferRecipientsFormZeroProps = new TransferRecipientsFormZeroProps();
  @HostBinding('class') myClass: string = this.classPrefix('View');
  @HostBinding('id') myId: string = this.idPrefix();
  ngUnsub = new Subject<void>();
  containerCard = new CardOneProps({
    title:"TransferRecipientsFormZero.title",
    customBody: null
  });

  listenForUpdate = () => {
    return this.props.setStateSubj.pipe(
      takeUntil(this.ngUnsub),
      tap((res?) => {
        this.props = new TransferRecipientsFormZeroProps({
          ...this.props,
          ...(res ?? this.props),
        });
        this.cdref.detectChanges();
      })
    );
  };

  mainForm = new WMLFormZeroProps({});
  mainFormGroup = this.formsService.transferRecipientsFormZero.mainForm;

  createMainFormField = (props: Partial<{ createWMLFieldFn: any, errorMsgKeyArray: any, fieldCustomProps: any, formControlName: keyof FormsService["transferRecipientsFormZero"]["mainForm"]["value"] }>) => {
    let { createWMLFieldFn, errorMsgKeyArray, fieldCustomProps,formControlName } = props;
    let field = this.baseService.createWMLField({
      i18nPrefix: "TransferRecipientsFormZero.mainForm",
      idPrefixFn: this.idPrefix,
      fieldParentForm: this.mainFormGroup,
      formControlName, createWMLFieldFn, errorMsgKeyArray, fieldCustomProps
    });
    return field;
  };

  recipientsProps = new MultiInputOneProps({
    clearForm:false,
    limit:1,
    getFieldCreatorPredicate:(index0)=>{
      return new MultiInputOneFieldCreatorProps({
        modifyFieldPredicate: (field)=>{
          field.deleteLabel();
          return field
        }
      })
    }
  })

  createRecipientsField = () => {
    let field = this.createMainFormField({
      formControlName: "recipients",
      createWMLFieldFn: this.specificService.createMultiInputField,
      errorMsgKeyArray: [],
      fieldCustomProps: this.recipientsProps
    });
    field.deleteRequiredLabelPart();
    return field;
  };

  initMainForm = () => {
    let fields = [
      this.createRecipientsField(),
    ];
    this.mainForm.updateFields(fields);
  };

  listenForReceipientMultiInputSubmit = ()=>{
    return this.recipientsProps.submitEvent
    .pipe(
      takeUntil(this.ngUnsub),
      concatMap((res)=>{
        return this.accountService.listCredits()
        .pipe(
          takeUntil(this.ngUnsub),
          map((userCredits)=>{
            return {
              res,credits:userCredits.credits[0]
            }
          })
        )
      }),
      tap(({res,credits})=>{
        // TODO might not be needed as users can now have negative amount of credits and would have to pay those back
        // let transferFilesJobsAreRunning = this.jobsService.listJobsDataSource.currentSource.data.find((item)=>{

        //   return item.job_type === "TRANSFER_LOCAL_FILES" && ["IN_PROGRESS"].includes(item.status) && item.details.transfer_local_files_details.client_type === "TransferLocalFilesJobZero.client.types.0"
        // })
        // if(![null,undefined].includes(transferFilesJobsAreRunning)){
        //   this.baseService.createWMLNote("TransferRecipientsFormZero.WMLNotifyOne.transferFilesJobsAreRunning",WMLNotifyOneBarType.Error)
        //   return
        // }
        if(credits.fileTransferCreditsAmount - this.props.files.length < 0){
          this.specificService.purchaseMoreCredits()
          this.baseService.closePopup()
          return
        }

        this.props.loadedFiles = this.props.files.map((fileInfo)=>{
          return {
            content: null,
            file:fileInfo.file,
            ...deepCopyInclude(["name","type","size"],fileInfo.file)
          }
        })

        this.mainFormGroup.controls.recipients.clear()
        res.value.value.forEach((identifier)=>{
          this.mainFormGroup.controls.recipients.push(new FormControl(identifier.value))
        })
        let jobId = this.jobsService.getIdForNewItemInListJobsDataSource()
        this.baseService.submitForm(
          new BaseServiceSubmitFormProps({
            rootFormGroup: this.mainFormGroup,
            cdref: this.cdref,
            validFormPredicateTypeDefault:{
              ngUnsub:this.ngUnsub,
              apiCall$:this.jobsService.requestFileTransfer(new RequestFileTransferUIRequestBody({
                jobId,
                recipients:this.mainFormGroup.value.recipients
              }))
            }
          })
        );
      }),
      this.baseService.closeOverlayLoadingViaRxjsFinalize
    )

  }

  listenForFileTransferRequestResult = ()=>{
    return this.socketioService.fileTransferRequestEvent
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res)=>{

        let listJob = new ListJobsEntity({
          id:res.data.result.job_id,
          jobType:"TRANSFER_LOCAL_FILES",
          details:{
            transferLocalFilesDetails:{
              clientType:"TransferLocalFilesJobZero.client.types.0",
              recipients:res.data.result.recipients,
              files:this.props.loadedFiles
            }
          }
        })
        this.jobsService.jobPanelItem.custom.props.addJobSubj.next(listJob)
        this.jobsService.jobPanelItem.open();
        this.baseService.closePopup()

      })
    )
  }

  ngOnInit(): void {
    this.initMainForm();
    this.listenForUpdate().subscribe();
    this.listenForReceipientMultiInputSubmit().subscribe()
    this.listenForFileTransferRequestResult().subscribe()
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}

@WMLConstructorDecorator
export class TransferRecipientsFormZeroProps {
  constructor(props: Partial<TransferRecipientsFormZeroProps> = {}) {}
  setStateSubj = new Subject<TransferRecipientsFormZeroProps>();
  setState = (value) => {
    this.setStateSubj.next(value);
  };
  files:WMLFileUploadZeroItem[] = []
  loadedFiles:ListJobsEntity["details"]["transferLocalFilesDetails"]["files"]
}
