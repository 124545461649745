import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { WMLConstructorDecorator, generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';
import { ENV } from '@env/environment';
import { JobsZeroCommonProps } from '../jobs-zero/jobs-zero';
import { PlatformsVideoDataEditorZeroActionEnum, PlatformsVideoDataEditorZeroSectionEnum } from '@shared/services/platforms/platforms.service';
import { ListJobsEntity } from '@shared/services/jobs/listJobs';

@Component({
    selector: 'update-videos-job-zero',
    templateUrl: './update-videos-job-zero.component.html',
    styleUrls: ['./update-videos-job-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UpdateVideosJobZeroComponent {
  classPrefix = generateClassPrefix('UpdateVideosJobZero');
  idPrefix = generateIdPrefix(ENV.idPrefix.updateVideosJobZero);

  @Input('props') props: UpdateVideosJobZeroProps = new UpdateVideosJobZeroProps();
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId: string = this.idPrefix();
  ngUnsub = new Subject<void>();

  PlatformsVideoDataEditorZeroSectionEnum = PlatformsVideoDataEditorZeroSectionEnum

  constructor(
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService
  ) {}

  listenForUpdate = () => {
    return this.props.setStateSubj.pipe(
      takeUntil(this.ngUnsub),
      tap((res?) => {
        this.props = new UpdateVideosJobZeroProps({
          ...this.props,
          ...(res ?? this.props)
        });
        this.cdref.detectChanges();
      })
    );
  }

  ngOnInit(): void {
    this.listenForUpdate().subscribe();
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}

@WMLConstructorDecorator
export class UpdateVideosJobZeroProps extends JobsZeroCommonProps {
  constructor(props: Partial<UpdateVideosJobZeroProps> = {}) {super();}
  title:string
  actionTypeEnumValue:PlatformsVideoDataEditorZeroActionEnum
  sectionTypeEnumValue:PlatformsVideoDataEditorZeroSectionEnum
  newValue:string
  changedVideos:ListJobsEntity["details"]["updateVideosDetails"]["videos"] =[]
  setStateSubj = new Subject<UpdateVideosJobZeroProps>();
  setState = (value)=>{
    this.setStateSubj.next(value)
  }

}
