import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';
import { Subject } from 'rxjs';
import { WMLConstructorDecorator,  WMLImage, generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';
import { ENV } from '@env/environment';
import { WMLButtonOneProps } from '@windmillcode/angular-wml-button';

@Component({
    selector: 'card-zero',
    templateUrl: './card-zero.component.html',
    styleUrls: ['./card-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardZeroComponent  {
  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService
  ) { }

  classPrefix = generateClassPrefix('CardZero')
  idPrefix = generateIdPrefix(ENV.idPrefix.cardZero)
  @Input('props') props: CardZeroProps = new CardZeroProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()

  ngOnInit(): void {
    this.props.cdref = this.cdref
  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }
}

@WMLConstructorDecorator
export class CardZeroProps {
  constructor(props: Partial<CardZeroProps> = {}) {}

  cdref:ChangeDetectorRef
  backgroundImg = new WMLImage({})
  commandBtns = Array(2)
  .fill(null)
  .map((nullVal,index0)=>{
    return  new WMLButtonOneProps({
      text:["Provide","WMLButtonOneProps Buttons"  ][index0],
    })
  })
  metadata:any = {}
}
