// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject, iif, of } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

// wml-components
import { generateClassPrefix } from '@windmillcode/wml-components-base';

// misc
import { ENV } from '@env/environment';
import { PricingCardZeroProps } from '../pricing-card-zero/pricing-card-zero.component';
import { StoreService } from '@shared/services/store/store.service';
import { StoreServiceItemPrice } from '@shared/services/store/models';
import { ListSubscriptionsUIRequestBody, ListSubscriptionsUIResponseBody } from '@shared/services/store/list';
import { PurchaseSubscriptionUIRequestBody } from '@shared/services/store/purchase';
import { AccountsService } from '@shared/services/accounts/accounts.service';

@Component({
    selector: 'pricing-zero',
    templateUrl: './pricing-zero.component.html',
    styleUrls: ['./pricing-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PricingZeroComponent {
  constructor(
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService,
    public subscriptionsService:StoreService,
    public accountsService:AccountsService
  ) {}

  classPrefix = generateClassPrefix('PricingZero');
  idPrefix = generateClassPrefix(ENV.idPrefix.pricingZero);
  @Input('props') props: PricingZeroProps = new PricingZeroProps({orig:true});
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId: string = this.idPrefix();
  ngUnsub = new Subject<void>();

  addSubscriptionToUser = (option:PricingCardZeroProps)=>{
    let reqBody = new PurchaseSubscriptionUIRequestBody({
      subscriptionContractIds:this.accountsService.currentUser.subscriptions.map((sub)=>sub.id),
      items:[{
        sellingPlanId:option.metadata.planId,
        variantId:option.metadata.productVariantId,
        quantity:1
      }],
      redirectUrl:this.utilService.getWindow().location.origin,
      accessToken:this.accountsService.currentUser?.accessToken
    })
    return this.subscriptionsService.purchaseSubscription(reqBody)
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res)=>{
        this.utilService.getWindow().open(res.weburl,"_self")


      })
    )
  }

  listPricingPlans = ()=>{

    return  iif(
      ()=>this.props.orig,
      this.subscriptionsService
      .listSubscriptionsDataSource
      .queryDataFromSource(new ListSubscriptionsUIRequestBody())
      .pipe(
        takeUntil(this.ngUnsub),
        tap((res:ListSubscriptionsUIResponseBody)=>{
          // @ts-ignore
          this.props.plans= res.data.map((group)=>{
            return group.sellingPlans.map((plan)=>{
              let card = new PricingCardZeroProps({
                name:group.name,
                // interval:enTranslations.PricingCardZero.intervals[makeLowerCase(plan.interval)],
                price : new StoreServiceItemPrice({
                  business:plan.price
                }),
                propSelectClick:()=>{
                  this.addSubscriptionToUser(card).subscribe()
                },
                description:plan.description,
                metadata:{
                  productId:group.productId,
                  planId:plan.id,
                  productVariantId:group.productVariant.id
                }
              })
              return card
            })
          }).flat(Infinity)
          this.cdref.detectChanges()
        })
      ),
      of(null),
    )
  }
  ngOnInit(): void {
    this.listPricingPlans().subscribe()
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}

export class PricingZeroProps {
  constructor(props: Partial<PricingZeroProps> = {}) {
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  orig =false
  plans: PricingCardZeroProps[] =[]
  close:Function
}
