// angular
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import {
  BaseService,
} from '@core/base/base.service';

// rxjs
import { Subject, concatMap, defer, takeUntil } from 'rxjs';

// wml-components
import {
  WMLCustomComponent,
  WMLImage,
  WMLUIProperty,
  generateClassPrefix,
  generateIdPrefix,
} from '@windmillcode/wml-components-base';

// misc

import { ENV } from '@env/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  WMLButtonOneProps,
} from '@windmillcode/angular-wml-button';
import { JobsService } from '@shared/services/jobs/jobs.service';
import { ListJobsEntity } from '@shared/services/jobs/listJobs';
import {
  ConfirmDialogZeroComponent,
  ConfirmDialogZeroProps,
} from '../confirm-dialog-zero/confirm-dialog-zero.component';
import { PlatformsService } from '@shared/services/platforms/platforms.service';
import { AccountsService } from '@shared/services/accounts/accounts.service';
import localforage from 'localforage';
import { ListUsersVideoDataUIResponseBody } from '@shared/services/platforms/listUsersVideoData';
import { WMLNotifyOneBarType } from '@windmillcode/angular-wml-notify';
import { DeleteVideosUIRequestBody } from '@shared/services/platforms/deleteVideos';





import { FormsService } from '@shared/services/forms/forms.service';
import { getUpdatedUserVideoData, getVideoDataEditorData } from './functions';
import { deepCopyExclude } from '@core/utility/object-utils';

@Component({
    selector: 'video-tile-zero',
    templateUrl: './video-tile-zero.component.html',
    styleUrls: ['./video-tile-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class VideoTileZeroComponent {
  constructor(
    public formsService: FormsService,
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService,
    public sanitizer: DomSanitizer,
    public jobsService: JobsService,
    public platformService: PlatformsService,
    public accountsService: AccountsService,
  ) {}

  classPrefix = generateClassPrefix('VideoTileZero');
  idPrefix = generateIdPrefix(ENV.idPrefix.videoTileZero);
  @Input('props') props: VideoTileZeroProps = new VideoTileZeroProps();
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId: string = this.idPrefix();
  ngUnsub = new Subject<void>();
  videoSrc: SafeUrl;
  ENV = ENV

  downloadBtn = new WMLButtonOneProps({
    text: 'VideoTileZero.downloadBtn',
    click: () => this.addToDownloadJob(),

  });

  deleteBtn = new WMLButtonOneProps({
    text: 'VideoTileZero.deleteBtn',
    click: () => this.confirmDeleteVideo(),
  });

  confirmDeleteVideo = () => {
    this.baseService.createWMLNote(
      'VideoTileZero.WMLNotifyOne.deleteVideoPending',
      WMLNotifyOneBarType.Info,
    );
    this.baseService.openPopup(
      new WMLCustomComponent({
        cpnt: ConfirmDialogZeroComponent,
        props: new ConfirmDialogZeroProps({
          propYesAction: () => {
            this.deleteVideo().subscribe();
          },
        }),
      }),
    );
  };

  deleteVideo = () => {
    let { googleDriveApiAccessToken, googleDriveApiRefreshToken, accessToken } =
      this.accountsService.currentUser;
    this.baseService.openOverlayLoading();

    return this.platformService
      .deleteVideos(
        new DeleteVideosUIRequestBody({
          ids: [this.props.videoId],
          googleDriveApiAccessToken,
          googleDriveApiRefreshToken,
          accessToken,
        }),
      )
      .pipe(
        takeUntil(this.ngUnsub),
        concatMap(() => {
          return defer(async () => {
            let userVideoData: ListUsersVideoDataUIResponseBody =
              await localforage.getItem(ENV.localForage.UserVideoData);
            userVideoData.videosRespModel =
              userVideoData.videosRespModel.filter(
                (video) => video.id !== this.props.videoId,
              );
            await localforage.setItem(
              ENV.localForage.UserVideoData,
              userVideoData,
            );
            this.utilService.getWindow().location.reload();
          });
        }),
      );
  };

  addToDownloadJob = () => {
    this.jobsService.jobPanelItem.custom.props.addVideosSubj.next(
      new ListJobsEntity({
        jobType: 'BULK_DOWNLOAD',
        details: {
          bulkDownloadDetails: {
            videos: [
              {
                title: this.props.videoTitle,
                thumbnailUrl: this.props.videoThumbnail.src,
                downloadUrl: this.props.downloadUrl,
                fileSize: this.props.fileSize,
                description: this.props.description,
                tags: this.props.tags,
              },
            ],
          },
        },
      }),
    );
    this.jobsService.jobPanelItem.open();
  };

  listenForPointerMoveOnContainer = () => {
    if(ENV.app.toPassChromeOauthVerficationTest){
      return
    }
    this.props.videoThumbnail.style.display = 'none';
    this.props.videoPlayer.style.display = 'block';
    this.cdref.detectChanges();
  };

  listenForPointerLeaveOnContainer = () => {
    this.props.videoThumbnail.style.display = 'block';
    this.props.videoPlayer.style.display = 'none';
    this.cdref.detectChanges();
  };

  toggleIsChosenForVideoDataEditor =(isChecked)=>{
    return getVideoDataEditorData()
    .pipe(
      takeUntil(this.ngUnsub),
      concatMap((data)=>{
        this.platformService.videoDataEditorSaved ="global.saveBtnNotSaved"
        this.platformService.videoDataEditorIsModified = true
        let isChosen =  this.props.isChosenForVideoDataEdit =isChecked
        this.cdref.detectChanges()
        let excludeArray = ["toggleIsChosenForVideoDataEditorEvent","toggleIsChosenForVideoDataEditorSubj"]
        // @ts-ignore
        data.items= data.items.filter((item)=>item.appId !== this.props.appId )
        .map((item)=>{
          return deepCopyExclude(excludeArray,item)
        })
        if(isChosen){
          // @ts-ignore
          data.items.push(deepCopyExclude(excludeArray,this.props))
        }
        return getUpdatedUserVideoData()
        .pipe(
          takeUntil(this.ngUnsub),
          concatMap((userVideoData)=>{
            return defer(async()=>{
              let sourceVideo = userVideoData.videosRespModel.find((video)=>video.id === this.props.videoId && video.platformTitle === this.props.platformTitle)
              sourceVideo.isChosenForVideoDataEdit = isChosen
              await localforage.setItem(
                ENV.localForage.UserVideoData,
                JSON.parse(JSON.stringify(userVideoData))
              )
              await localforage.setItem(ENV.localForage.VideoDataEditor,
                JSON.parse(JSON.stringify(data))
              )
              this.props.toggleIsChosenForVideoDataEditorEvent.next()
            })
          })
        )



      })
    )

  }

  listenForIsChosenForVideoDataEditor =()=>{
    return this.props.toggleIsChosenForVideoDataEditorSubj
    .pipe(
      takeUntil(this.ngUnsub),
      concatMap((res)=>{
        return this.toggleIsChosenForVideoDataEditor(res)
      })
    )

  }


  ngOnInit(): void {

    if (this.props.videoPlayer) {
      this.videoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.props.videoPlayer.src,
      );
    }
    if (!this.props.videoThumbnail.onError) {
      this.props.videoThumbnail.onError = () => {
        this.props.videoThumbnail = this.baseService.onImageFailedToLoad(
          this.props.videoThumbnail,
        );
        this.cdref.detectChanges();
      };
    }
    this.listenForIsChosenForVideoDataEditor().subscribe()
    this.cdref.detectChanges();
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}

export class VideoTileZeroPlayer extends WMLUIProperty {
  constructor(props: Partial<VideoTileZeroPlayer> = {}) {
    super(props);
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

  src: string;
}

export class VideoTileZeroProps {
  constructor(
    props: Partial<
      VideoTileZeroProps & {
        propVideoThumbnailSrc;
        propVideoPlayerUrl;
        propPlatformIcon;
        propChannelIcon;
      }
    > = {},
  ) {
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
    if (props.propVideoThumbnailSrc) {
      this.videoThumbnail.src = props.propVideoThumbnailSrc;
    }
    if (props.propVideoPlayerUrl) {
      this.videoPlayer.src = props.propVideoPlayerUrl;
    }
    if (props.propPlatformIcon) {
      this.platformIcon.src = props.propPlatformIcon;
    }
    if (props.propChannelIcon) {
      this.channelIcon.src = props.propChannelIcon;
    }
    if ([null, undefined, ''].includes(this.playlistTitle)) {
      this.playlistTitle = 'global.na';
    }
  }
  // A unique identifier for the videoTile
  get appId(){
    return this.platformTitle+this.videoId
  }
  set videoThumbnailSrc(val){
    this.videoThumbnail.src = val
  }
  isChosenForVideoDataEdit = false
  videoId:string;
  videoTitle: string;
  description: string;
  tags: string[];
  platformIcon = new WMLImage({});
  platformTitle:string;
  channelTitle:string;
  channelIcon = new WMLImage({});
  playlistTitle: string;
  downloadUrl: string;

  videoPlayer = new VideoTileZeroPlayer({});
  videoThumbnail = new WMLImage({});
  type: 'youtube' | 'vimeo' | 'yivister' | 'theblacktube';
  fileSize: number;
  toggleIsChosenForVideoDataEditorSubj = new Subject<boolean>()
  toggleIsChosenForVideoDataEditorEvent = new Subject<void>()

}
