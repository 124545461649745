import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';
import { generateClassPrefix, WMLConstructorDecorator } from '@windmillcode/wml-components-base';
import { ENV } from '@env/environment';
import { ListJobsEntity } from '@shared/services/jobs/listJobs';
import enTranslations from "src/assets/i18n/en.json";
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'job-status-zero',
    templateUrl: './job-status-zero.component.html',
    styleUrls: ['./job-status-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class JobStatusZeroComponent  {
  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService,
  ) {}

  classPrefix = generateClassPrefix('JobStatusZero')
  idPrefix = generateClassPrefix(ENV.idPrefix.jobStatusZero)
  @Input('props') props: JobStatusZeroProps = new JobStatusZeroProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()
  enTranslations= enTranslations

  listenForUpdate = ()=>{
    return this.props.setStateSubj
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res?)=>{
        this.props = new JobStatusZeroProps({
          ...this.props,
          ...(res ?? this.props)
        })
        this.cdref.detectChanges()
      })
    )
  }

  ngOnInit(): void {
    this.listenForUpdate().subscribe()
  }

}


@WMLConstructorDecorator
export class JobStatusZeroProps {
  constructor(params: Partial<JobStatusZeroProps> = {}) { }

  status:ListJobsEntity["status"] = "NOT_STARTED"
  resultURL:string
  resultURLIsPresent = true
  setStateSubj = new Subject<JobStatusZeroProps|void>()
  setState = (props:JobStatusZeroProps|void)=>{
    this.setStateSubj.next(props)
  }
}
