// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,  Input   } from '@angular/core';


// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';




// rxjs
import { Subject } from 'rxjs';

// wml-components
import { WMLUIProperty, generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';


// misc

import { ENV } from '@env/environment';



@Component({
    selector: 'dropdown-option-two',
    templateUrl: './dropdown-option-two.component.html',
    styleUrls: ['./dropdown-option-two.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DropdownOptionTwoComponent  {

  constructor(
    public cdref:ChangeDetectorRef,

    public utilService:UtilityService,
    public baseService:BaseService

  ) { }

  classPrefix = generateClassPrefix('DropdownOptionTwo')

  idPrefix = generateIdPrefix(ENV.idPrefix.dropdownOptionTwo)


  @Input('props') props: DropdownOptionTwoProps = new DropdownOptionTwoProps()


  @HostBinding('class') myClass: string = this.classPrefix(`View`);

  @HostBinding('id') myId:string = this.idPrefix()


  ngUnsub= new Subject<void>()

  ngOnInit(): void {

  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}



export class DropdownOptionTwoProps extends WMLUIProperty {
  constructor(props:Partial<DropdownOptionTwoProps>={}){
    super()
    let origProps = Object.entries(props)
    .filter(([key,val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) })
  }
}


